import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Removal is a non-destructive action. A user can remove an asset from a list or item without destroying it.`}</p>
    </PageDescription>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p><a parentName="p" {...{
        "href": "/experimental/overview"
      }}>{`Experimental`}</a></p>
    <h4 {...{
      "id": "maintainers"
    }}>{`Maintainers:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vikkipaterson"
      }}>{`Vikki Paterson`}</a></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.418397626112764%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABnUlEQVQoz2WRzU7CUBCFeSaNqOBGH4A/WagLdyx8DY3Q8tcCLdCGH3kECCQkRggIiErBhK0bgZiwAtYujtNLL6Iuvsy099zTM1PbarlA/7mLTu8JnW6P6pqX1wGM4QgDY0h1mxE747ptlqsVbK1WGzu7Ozg+OcGhw4E9u53Yp94J59HRD04L6rd19v0D1tsPHBgO32Cr1urw+vyISzKub24hiGEIggCREEIhBINBiKKIMCccRsQkEmGEmIbuCSImkwlslUoZHo8HxbsSUoqCTCazIZvNMjRCt9CsdxkT0qTTaYaqqphOp6ZhBV6vF/l8niWJxWKMeDwOwzAwm80wn8/xQXzSvgfjMaKUjOvMlKY2lUqtE5bLZbjdbhQKBUSjUUiSxJBlGbVqFY1mA932Ixa9Pr4GI7zfPzADruNahabbGLpcLpRKJfYV84ATCATgpvQXp37Uzy/R9J2hEriCQuNt61Tr+ZdhsVhEMpnc7I5XXdeRo3Uo+RwkXUNMVZBIJP5hpmQ7/GvIfoKmMUOemBlnNaSpl60ROdyMG34D48zt+ODv5bAAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a removal using a modal in context",
        "title": "Example of a removal using a modal in context",
        "src": "/static/91059b416774aa81a3af22ab36ab88e2/fb070/modal-in-context.png",
        "srcSet": ["/static/91059b416774aa81a3af22ab36ab88e2/d6747/modal-in-context.png 288w", "/static/91059b416774aa81a3af22ab36ab88e2/09548/modal-in-context.png 576w", "/static/91059b416774aa81a3af22ab36ab88e2/fb070/modal-in-context.png 1152w", "/static/91059b416774aa81a3af22ab36ab88e2/fb104/modal-in-context.png 1728w", "/static/91059b416774aa81a3af22ab36ab88e2/902fb/modal-in-context.png 2304w", "/static/91059b416774aa81a3af22ab36ab88e2/6690f/modal-in-context.png 3370w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a remove pattern using a modal</Caption>
    <h2 {...{
      "id": "low-impact"
    }}>{`Low impact`}</h2>
    <p>{`User confirmation is not required for low-impact removals because the asset is not destroyed.`}</p>
    <h2 {...{
      "id": "high-impact"
    }}>{`High impact`}</h2>
    <p>{`Require user confirmation for high impact removals. Communicate the consequences of the removal, and let the user know if the removal is permanent.`}</p>
    <p>{`Optionally, when the remove button is selected, change it to ‘Removing…’.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.66847826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABYUlEQVQoz6WSzU7CQBDHe1EhhkjwJnrx7CPwOIYYMEJilCfgOUyMr6LxSmnTJiSEj1AFaYGuhX7/3d1SrKF6wEl+yWQ7+8vMdoQwDMFgEee7EN8XsI74cNeI7wtJu23bWC6XGyzL4qxWq1RYPfvued53h8mWJ5MJVFVFt9tFp9NBv9/HfD4HIYRjmuYWuq5zcapQ0zRIkgRFUbi41+thOBxGDAb4GI9BmGixAEngOk66kI05nU5hGAbvzHVdOLSYXSCODdN18Om5IGt4TmvcIPgpZBHQQzamKIqQZZl3yvI2pUV5b0tYiBJm7SQyjJYIWze2heyRS6UScrkcstksMpkM55DmwsE+Ho+LGJ1fQC6cQjk6gZIvQi2cQd7LY/bwFAnpz9kI2XjNZhPlchnVahWVSoVzTbmkvNzcwqg3MKrd4S2mfg/tqgbr+TUS+kG0h//dwV/3kL2j7/uphH+RcHwBoDwjOLWhaS8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a high-impact remove modal",
            "title": "Example of a high-impact remove modal",
            "src": "/static/856e5b23676bf361af731b03f106d268/fb070/first-view.png",
            "srcSet": ["/static/856e5b23676bf361af731b03f106d268/d6747/first-view.png 288w", "/static/856e5b23676bf361af731b03f106d268/09548/first-view.png 576w", "/static/856e5b23676bf361af731b03f106d268/fb070/first-view.png 1152w", "/static/856e5b23676bf361af731b03f106d268/c3e47/first-view.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a high-impact remove modal</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.80434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABT0lEQVQoz62Qy07CQBSGeQCjrERd6XO49HFceImXR3Dhq7jQhYS9WwMUCEIxJIVSS4Fq7xfK/M4MTClqjFEn+fKfc9LzddocISQAELD8LWKfZQ7/fJiQsBOGIQmCgMNq3/eJ53npTMyzsGeSJCHCwTI3T0DXdTSbTXS7XciyDEVRYFkWXNflOI7De9u2UyaTCaIo4vvCkwqHVNh+auG50+H0ez1ogwEGqgq138fIMOAwEZNmiOP4a6FJb6C8aFCNIbTxCJbv48330rTDAB5dduOIw2t6u+lstirkDR2arTbGVQnjWp3SgFGtLZFYShhWFtCa9Xq5gsA0PwuTMMTD/gFKhV2UtvZwv15AMb+N4sYH8pnM7+BubRPaze1cOJ0uhTPayFfXqB+do3F6SfOM199yfAHp8ASvj2WIr1z5h385WUdODNgbSCJIfs5CJvIdpC8qH290H+AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a high-impact remove modal in its 'removing' state",
            "title": "Example of a high-impact remove modal in its 'removing' state",
            "src": "/static/158397f26ebb99008aba3ae78a13de90/fb070/second-view.png",
            "srcSet": ["/static/158397f26ebb99008aba3ae78a13de90/d6747/second-view.png 288w", "/static/158397f26ebb99008aba3ae78a13de90/09548/second-view.png 576w", "/static/158397f26ebb99008aba3ae78a13de90/fb070/second-view.png 1152w", "/static/158397f26ebb99008aba3ae78a13de90/c3e47/second-view.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">
  Example of a high-impact remove modal following user confirmation
        </Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      